<template>
    <div>
        <template v-if="items.length === 1">
            {{ items[0] }}
        </template>
        <template v-else>
            <ul>
                <li v-for="text in items">{{ text }}</li>
            </ul>
        </template>
    </div>
</template>

<script lang="ts" type="typescript">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class List extends Vue {
        @Prop({ default: [] })
        items: Array<string>;
    }
</script>