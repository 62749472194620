interface Config {
    api: {
        host: string,
    };
    books: {
        shippingCost: string,
    };
    dateformat: string;
    datetimeformat: string;
    filters: {
        minYear: number,
        maxYear: number,
    };
    locales: {
        en: string,
        nl: string,
        // fr: string,
        // de: string,
    },
    idle: {
        timeout: number;
    },
    customerDiscount: {
        rate: {
            5: {
                start: number,
                end: number,
            },
            10: {
                start: number,
                end: number,
            },
            any: {
                value: number,
            }
        }
    },
    standardMailTypes: {
        [key: string]: string
    }
    version: string,
}

declare let process: any;

export default {
    api: {
        host: process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : "https://127.0.0.1:3000",
    },
    books: {
        shippingCost: "5,00"
    },
    dateformat: "DD-MM-YYYY",
    datetimeformat: "DD-MM-YYYY HH:mm",
    filters: {
        minYear: 1990,
        maxYear: 2018,
    },
    idle: {
        timeout: 500
    },
    locales: {
        // Key wordt gebruikt door de handleiding dus dezelfde naam als op de pagina dropdown/store aanhouden.
        // Value moet lowercase met een - volgens de BCP 47 language tags.
        en: "en-us",
        nl: "nl-nl",
        // fr: "fr-fr",
        // de: "de-de"
    },
    standardMailTypes: {
        order: 'Single order',
        subscription: 'Subscription',
        orderBase: 'Order',
        internal: 'Internal',
        message: 'Message',
        cancellation: 'Cancellation',
        budget: 'Budget'
    },
    customerDiscount: {
        rate: {
            5: {
                start: 10,
                end: 29
            },
            10: {
                start: 30,
                end: 99
            },
            any: {
                value: 100
            }
        }
    },
    version: '3.2.2',
} as Config;
